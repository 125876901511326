import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import {
  EloCheckCircleIcon,
  EloWarningCircleIcon,
  EloUnreadableIcon,
  EloSearchIcon,
  EloInfoIcon,
} from '@elo-ui/components/icons/regular'

import { EloTooltip } from '@elo-ui/components/elo-tooltip'

import { TEST_IDS, TooltipProps } from '@elo-ui/types'

import '../elo-input.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'password' | 'email' | 'search' | 'number'
  label?: string
  labelDescription?: string
  required?: boolean
  error?: boolean
  success?: boolean
  successText?: string
  errorText?: string
  hintText?: string
  showCounter?: boolean
  dataTestId?: string
  inputSize?: 'large' | 'xlarge'
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onSearchSubmit?: (e: React.MouseEvent<HTMLSpanElement>, value: string) => void
  onKeyEnter?: (value: string) => void
  children?: React.ReactNode
  tooltipOptions?: TooltipProps
  autoFocus?: boolean
  renderIcon?: () => React.ReactNode
  isTouched?: boolean
  enterKeyHint?: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send'
}

export const EloInput: React.FC<Props> = ({
  type = 'text',
  name,
  id,
  defaultValue = '',
  placeholder = '',
  label = '',
  labelDescription = '',
  error = false,
  success = false,
  errorText = '',
  successText = '',
  hintText = '',
  showCounter = false,
  disabled = false,
  className,
  onChange,
  onBlur,
  onFocus,
  onSearchSubmit,
  onKeyEnter,
  dataTestId = TEST_IDS.input,
  maxLength,
  minLength,
  required,
  inputSize = 'xlarge',
  tooltipOptions,
  renderIcon,
  autoFocus,
  value,
  isTouched = false,
  enterKeyHint = 'enter',
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue)
  const [currentType, setCurrentType] = useState(type)
  const [touched, setTouched] = useState(isTouched)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const inputClasses = classNames(className, 'elo-input', `elo-input--${type}`, `elo-input--${inputSize}`, {
    'elo-input--error': touched && error,
    'elo-input--success': success,
    disabled,
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(event.target.value)
    setTouched(true)
    onChange && onChange(event)
  }

  const handleClickPassword = useCallback(
    () => setCurrentType(currentType === 'password' ? 'text' : 'password'),
    [currentType]
  )

  const handleKeyEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onKeyEnter && onKeyEnter(value?.toString())
      }
    },
    [value]
  )

  const maxCharsLength = Number(maxLength) < 0 ? 0 : maxLength
  const minCharsLength = Number(minLength) < 0 ? 0 : minLength

  const getIcon = () => {
    if (type === 'password') {
      return (
        <span className='elo-input__password-button' onClick={handleClickPassword}>
          <EloUnreadableIcon />
        </span>
      )
    }

    if (type === 'search') {
      return (
        <span onClick={(e) => onSearchSubmit(e, currentValue.toString())} className='elo-input--search__search-button'>
          <EloSearchIcon />
        </span>
      )
    }

    if (touched && error) {
      return <EloWarningCircleIcon />
    }

    if (success) {
      return <EloCheckCircleIcon />
    }
  }

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus()
    }
  }, [autoFocus])

  return (
    <div className={inputClasses}>
      <label className='elo-input__label'>
        {label && (
          <span className='elo-input__label-text'>
            {label}
            {required && <span className='elo-input__label-required'>*</span>}
            {labelDescription && <span className='elo-input__label-description'> {labelDescription} </span>}
            {tooltipOptions && (
              <span className='elo-input__label-tooltip'>
                <EloTooltip {...tooltipOptions}>
                  {tooltipOptions.children ? tooltipOptions.children : <EloInfoIcon size={16} />}
                </EloTooltip>
              </span>
            )}
          </span>
        )}

        <input
          value={typeof value !== 'undefined' ? value : currentValue}
          aria-disabled={disabled}
          data-testid={dataTestId}
          tabIndex={disabled ? -1 : 0}
          readOnly={disabled}
          ref={inputRef}
          maxLength={maxCharsLength}
          minLength={minCharsLength}
          onChange={handleChange}
          onKeyDown={handleKeyEnter}
          type={currentType}
          onBlur={(e) => {
            setTouched(true)
            onBlur && onBlur(e)
          }}
          {...{
            id,
            name,
            placeholder,
            onFocus,
            required,
          }}
          enterKeyHint={enterKeyHint}
        />
        <span className='elo-input__icon'>{getIcon()}</span>
        {renderIcon && renderIcon()}
      </label>
      {showCounter && maxLength && (
        <span className='elo-input__counter'>{`${String(currentValue || value || '').length}/${maxCharsLength}`}</span>
      )}
      {success && successText && <span className='elo-input__success'>{successText}</span>}
      {touched && error && errorText && (
        <span className='elo-input__error' dangerouslySetInnerHTML={{ __html: errorText }} />
      )}
      {hintText && <span className='elo-input__hint'>{hintText}</span>}
    </div>
  )
}
