import React, { InputHTMLAttributes, ChangeEvent, FocusEvent, useCallback, useState, useEffect } from 'react'
import classNames from 'classnames'

import { EloTooltip } from '@elo-ui/components/elo-tooltip'
import { EloInfoIcon } from '@elo-ui/components/icons/regular'
import { TEST_IDS, TooltipProps } from '@elo-ui/types'

import '../elo-input.scss'

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  labelDescription?: string
  required?: boolean
  error?: boolean
  success?: boolean
  successText?: string
  errorText?: string
  hintText?: string
  showCounter?: boolean
  dataTestId?: string
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onFocus?: (e: FocusEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void
  resize?: 'none' | 'both' | 'horizontal' | 'vertical'
  rows: number
  tooltipOptions?: TooltipProps
}

export const EloTextarea: React.FC<Props> = ({
  name,
  id,
  defaultValue = '',
  placeholder = '',
  label = '',
  labelDescription = '',
  error = false,
  success = false,
  errorText = '',
  successText = '',
  hintText = '',
  showCounter = false,
  disabled = false,
  className,
  onChange,
  onBlur,
  onFocus,
  dataTestId = TEST_IDS.input,
  maxLength,
  minLength,
  required,
  resize,
  tooltipOptions,
  ...restProps
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue)

  useEffect(() => {
    setCurrentValue(defaultValue)
  }, [defaultValue])

  const inputClasses = classNames(className, 'elo-input', `elo-input--textarea`, {
    'elo-input--error': error,
    'elo-input--success': success,
    'elo-input--with-resize': resize,
    disabled,
  })

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setCurrentValue(event.target.value)
      onChange && onChange(event)
    },
    [onChange]
  )

  const maxCharsLength = Number(maxLength) < 0 ? 0 : maxLength
  const minCharsLength = Number(minLength) < 0 ? 0 : minLength

  return (
    <div className={inputClasses}>
      <label className='elo-input__label'>
        {label && (
          <span className='elo-input__label-text'>
            {label}
            {required && <span className='elo-input__label-required'>*</span>}
            {labelDescription && <span className='elo-input__label-description'> {labelDescription} </span>}
            {tooltipOptions && (
              <span className='elo-input__label-tooltip'>
                <EloTooltip {...tooltipOptions}>
                  {tooltipOptions.children ? tooltipOptions.children : <EloInfoIcon size={16} />}
                </EloTooltip>
              </span>
            )}
          </span>
        )}
        <textarea
          value={currentValue}
          aria-disabled={disabled}
          data-testid={dataTestId}
          tabIndex={disabled ? -1 : 0}
          readOnly={disabled}
          maxLength={maxCharsLength}
          minLength={minCharsLength}
          onChange={handleChange}
          {...{
            id,
            name,
            placeholder,
            onBlur,
            onFocus,
            required,
            resize,
            ...restProps,
          }}
        />
      </label>
      {showCounter && maxLength && (
        <span className='elo-input__counter'>{`${String(currentValue).length}/${maxCharsLength}`}</span>
      )}
      {success && successText && <span className='elo-input__success'>{successText}</span>}
      {error && errorText && <span className='elo-input__error'>{errorText}</span>}
      {hintText && <span className='elo-input__hint'>{hintText}</span>}
    </div>
  )
}
