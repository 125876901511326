import React, { Fragment, useEffect, useState } from 'react'
import Cleave from 'cleave.js/react'
import classNames from 'classnames'

import Input from 'ui-old/Input'
import Select from 'ui-old/Select'
import { EloInfoIcon } from '@elo-ui/components/icons/regular'
import { EloPopover } from '@elo-ui/components/elo-popover'

import { CURRENT_YEAR } from '@elo-kit/constants/dateTime.constants'
import { isProduction } from 'utils/env.utils'
import { validators } from 'utils/validatorsShared.utils'
import { linkToImage } from 'utils/ssr.utils'

import Amex from './assets/cc-types/Amex.png'
import Discover from './assets/cc-types/Discover.png'
import MasterCard from './assets/cc-types/Mastercard.png'
import Visa from './assets/cc-types/Visa.png'
import Reverse from './assets/cc-types/Reverse.png'
import Cvv3 from './assets/cc-types/Cvv3.png'
import Cvv4 from './assets/cc-types/Cvv4.png'

export interface Card {
  isValid?: boolean
  number?: string
  cvv?: string
  year?: string
  month?: string
}

interface CreditCardProps {
  forceDirty: boolean
  updateCreditCardData: (card: Card) => void
  isNewPaymentMethodsUI: boolean
}

export const CreditCard: React.FC<CreditCardProps> = ({ forceDirty, updateCreditCardData, isNewPaymentMethodsUI }) => {
  const [creditCardType, setCreditCardType] = useState<string>('')
  const [dirty, setDirty] = useState<Record<string, boolean>>({})
  const [allDirty, setAllDirty] = useState<boolean>(false)
  const [validState, setValidState] = useState<Record<string, boolean>>({
    number: false,
    cvv: false,
    year: false,
    month: false,
  })
  const [creditCard, setCreditCard] = useState<Card>({
    isValid: false,
    number: '',
    cvv: '',
    year: '',
    month: '',
  })

  useEffect(() => {
    updateCreditCardData(creditCard)
  }, [creditCard])

  useEffect(() => {
    if (forceDirty) {
      setAllDirty(true)
      setDirty({
        number: true,
        cvv: true,
        year: true,
        month: true,
      })
      checkFullyValid()
    }
  }, [forceDirty])

  useEffect(() => {
    checkFullyValid()
  }, [dirty, validState])

  const handleCardState = (prop, value) => {
    setCreditCard((prevState) => ({
      ...prevState,
      [prop]: value,
    }))
  }

  const handleCardValid = (prop, value, blur) => {
    if (dirty.number || blur) {
      const isValid =
        validators.isCreditCard(value) ||
        (!isProduction() &&
          validators.isLength(value, {
            max: 16,
            min: 16,
          }))
      handleValid(prop, isValid)
    }
  }

  const handleValid = (inputName, value) => {
    setDirty((prevDirty) => ({
      ...prevDirty,
      [inputName]: true,
    }))
    setValidState((prevValidState) => ({
      ...prevValidState,
      [inputName]: value,
    }))
    checkFullyValid()
  }

  const checkFullyValid = () => {
    const { cvv, number, month, year } = validState
    const isValid = number && month && year && cvv

    setAllDirty(Object.keys(dirty).length === 4)
    setCreditCard((prevCreditCard) => ({ ...prevCreditCard, isValid }))
  }

  const onCreditCardTypeChanged = (creditCardType) => setCreditCardType(creditCardType)

  const selectYear = () => {
    const arr = [
      <option key={0} value=''>
        {I18n.t('react.shared.payment_methods.credit_card.year')}
      </option>,
    ]
    for (let i = CURRENT_YEAR; i <= CURRENT_YEAR + 15; i += 1) {
      arr.push(
        <option key={i} value={i}>
          {i}
        </option>
      )
    }
    return arr
  }

  const selectMonth = () => {
    const arr = [
      <option key={0} value=''>
        {I18n.t('react.shared.payment_methods.credit_card.month')}
      </option>,
    ]
    for (let i = 1; i < 10; i += 1) {
      arr.push(
        <option key={i} value={`0${i}`}>
          {i}
        </option>
      )
    }
    arr.push(
      <option key={10} value={10}>
        {10}
      </option>
    )
    arr.push(
      <option key={11} value={11}>
        {11}
      </option>
    )
    arr.push(
      <option key={12} value={12}>
        {12}
      </option>
    )

    return arr
  }

  const { cvv, isValid, month, year } = creditCard

  const getCreditCardImg = () => {
    switch (creditCardType) {
      case 'mastercard':
        return linkToImage(MasterCard)
      case 'visa':
        return linkToImage(Visa)
      case 'amex':
        return linkToImage(Amex)
      case 'discover':
        return linkToImage(Discover)
      default:
        return ''
    }
  }

  const creditCardImg = getCreditCardImg()

  const cardInputClasses = classNames('form-control', {
    'is-invalid': dirty.number && !validState.number,
    'new-card-input': isNewPaymentMethodsUI,
  })

  const cardLogoClasses = classNames('credit-card-logo flipInY animated', {
    'new-credit-card-logo': isNewPaymentMethodsUI,
  })

  const cardSelectClasses = classNames('form-control select pl-1 pr-0', {
    'new-card-select': isNewPaymentMethodsUI,
  })

  const cardCvvClasses = classNames('form-control', {
    'new-card-input': isNewPaymentMethodsUI,
  })

  return (
    <Fragment>
      {!isNewPaymentMethodsUI && (
        <div className='card-secure-info'>
          <i className='fas fa-lock mr-1' />
          {I18n.t('react.shared.payment_methods.secured')}
        </div>
      )}
      <div className='row'>
        <div className='col-12'>
          <div className='form-group'>
            {isNewPaymentMethodsUI && <div className='new-card-label'>{I18n.t('shared.payment_form.credit_card')}</div>}
            <Cleave
              className={cardInputClasses}
              placeholder={I18n.t('react.shared.payment_methods.credit_card.placeholder')}
              options={{
                creditCard: true,
                onCreditCardTypeChanged: onCreditCardTypeChanged,
              }}
              onChange={(e) => {
                const { rawValue } = e.target
                handleCardState('number', rawValue)
                handleCardValid('number', rawValue, false)
              }}
              onBlur={(e) => handleCardValid('number', e.target.rawValue, true)}
            />
          </div>
          {creditCardImg !== '' && (
            <span>
              <img className={cardLogoClasses} alt='Card type' src={creditCardImg} />
            </span>
          )}
        </div>
        <div className='col-4 pr-1'>
          {isNewPaymentMethodsUI && (
            <div className='new-card-label'>{I18n.t('react.shared.payment_methods.credit_card.month')}</div>
          )}
          <Select
            wrapClass='form-group select'
            hideErrorMsg
            class={cardSelectClasses}
            options={selectMonth()}
            forceDirty={forceDirty}
            value={month}
            validations={['required']}
            handleInput={(value) => handleCardState('month', value)}
            handleValid={(isValid) => handleValid('month', isValid)}
          />
        </div>
        <div className='col-4 pl-1'>
          {isNewPaymentMethodsUI && (
            <div className='new-card-label'>{I18n.t('react.shared.payment_methods.credit_card.year')}</div>
          )}
          <Select
            wrapClass='form-group select'
            hideErrorMsg
            class={cardSelectClasses}
            options={selectYear()}
            forceDirty={forceDirty}
            value={year}
            validations={['required']}
            handleInput={(value) => handleCardState('year', value)}
            handleValid={(isValid) => handleValid('year', isValid)}
          />
        </div>
        <div className='col-4 pl-1'>
          {isNewPaymentMethodsUI && (
            <div className='new-card-label new-card-label--flex'>
              {I18n.t('react.shared.payment_methods.credit_card.cvv')}
              <EloPopover
                show={false}
                placement='top-center'
                content={
                  <div className='new-card-tooltip'>
                    <div className='new-card-tooltip__label'>
                      {I18n.t('react.shared.payment_methods.credit_card.find_cvv')}
                    </div>
                    <div className='new-card-tooltip__flex'>
                      <div>
                        <div className='new-card-tooltip__flex--label'>
                          {I18n.t('react.shared.payment_methods.credit_card.types')}
                        </div>
                        <div className='new-card-tooltip__flex--text'>
                          {I18n.t('react.shared.payment_methods.credit_card.3_digits')}
                        </div>
                        <img className='new-card-tooltip__flex--image' alt='Cvv 3 digit' src={linkToImage(Cvv3)} />
                      </div>
                      <div>
                        <div className='new-card-tooltip__flex--label'>
                          {I18n.t('react.shared.payment_methods.credit_card.amex')}
                        </div>
                        <div className='new-card-tooltip__flex--text'>
                          {I18n.t('react.shared.payment_methods.credit_card.4_digits')}
                        </div>
                        <img className='new-card-tooltip__flex--image' alt='Cvv 4 digit' src={linkToImage(Cvv4)} />
                      </div>
                    </div>
                  </div>
                }
              >
                <EloInfoIcon size={16} />
              </EloPopover>
            </div>
          )}
          <Input
            wrapClass='form-group'
            hideErrorMsg
            class={cardCvvClasses}
            placeholder={I18n.t('react.shared.payment_methods.credit_card.cvv')}
            forceDirty={forceDirty}
            value={cvv}
            maxlength={3}
            validations={['required', 'cvv']}
            handleInput={(value) => handleCardState('cvv', value)}
            handleValid={(isValid) => handleValid('cvv', isValid)}
          />
          {cvv.length > 0 && (
            <span className='d-none d-sm-block'>
              <img className={cardLogoClasses} alt='reverse' src={linkToImage(Reverse)} />
            </span>
          )}
        </div>
      </div>
      {!isValid && allDirty && (
        <small className='text-danger'>{I18n.t('react.shared.payment_methods.credit_card.error_message')}</small>
      )}
    </Fragment>
  )
}
