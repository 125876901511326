import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

import { notify } from 'libs/common/notify'
import { EloCopyIcon, EloInfoIcon } from '@elo-ui/components/icons/regular'
import { EloTooltip } from '@elo-ui/components/elo-tooltip'

import { TEST_IDS, TooltipProps } from '@elo-ui/types'

import '../elo-input.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  labelDescription?: string
  required?: boolean
  error?: boolean
  success?: boolean
  successText?: string
  errorText?: string
  hintText?: string
  showCounter?: boolean
  dataTestId?: string
  tooltipOptions?: TooltipProps
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
}

export const EloInputCopy: React.FC<Props> = ({
  name,
  id,
  defaultValue = '',
  placeholder = '',
  label = '',
  labelDescription = '',
  error = false,
  success = false,
  errorText = '',
  successText = '',
  hintText = '',
  showCounter = false,
  disabled = false,
  className,
  onChange,
  onBlur,
  onFocus,
  dataTestId = TEST_IDS.input,
  maxLength,
  minLength,
  required,
  tooltipOptions,
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue)

  useEffect(() => {
    setCurrentValue(defaultValue)
  }, [defaultValue])

  const inputClasses = classNames(className, 'elo-input', `elo-input--copy`, {
    'elo-input--error': error,
    'elo-input--success': success,
    disabled,
  })

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      setCurrentValue(event.target.value)
      onChange(event)
    }
  }, [])
  const handleCopy = useCallback(() => {
    if (!disabled) {
      navigator.clipboard.writeText(String(currentValue))
      notify('success', I18n.t('react.shared.notific.copied'))
    }
  }, [currentValue])

  const maxCharsLength = Number(maxLength) < 0 ? 0 : maxLength
  const minCharsLength = Number(minLength) < 0 ? 0 : minLength

  return (
    <div className={inputClasses}>
      <label className='elo-input__label'>
        {label && (
          <span className='elo-input__label-text'>
            {label}
            {required && <span className='elo-input__label-required'>*</span>}
            {labelDescription && <span className='elo-input__label-description'> {labelDescription} </span>}
            {tooltipOptions && (
              <span className='elo-input__label-tooltip'>
                <EloTooltip {...tooltipOptions}>
                  {tooltipOptions.children ? tooltipOptions.children : <EloInfoIcon size={16} />}
                </EloTooltip>
              </span>
            )}
          </span>
        )}
        <input
          type='text'
          value={currentValue}
          aria-disabled={disabled}
          data-testid={dataTestId}
          tabIndex={disabled ? -1 : 0}
          readOnly={disabled || !onChange}
          maxLength={maxCharsLength}
          minLength={minCharsLength}
          onChange={handleChange}
          {...{
            id,
            name,
            placeholder,
            onBlur,
            onFocus,
            required,
          }}
        />
        {!!String(defaultValue).length && (
          <span className='elo-input__icon' onClick={handleCopy}>
            <EloCopyIcon />
          </span>
        )}
      </label>
      {showCounter && maxLength && (
        <span className='elo-input__counter'>{`${String(currentValue).length}/${maxCharsLength}`}</span>
      )}
      {success && successText && <span className='elo-input__success'>{successText}</span>}
      {error && errorText && <span className='elo-input__error'>{errorText}</span>}
      {hintText && <span className='elo-input__hint'>{hintText}</span>}
    </div>
  )
}
