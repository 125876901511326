import React, { Component } from 'react'

import { I18nContext } from '@elo-kit/components/i18n/i18n'

class Select extends Component {
  state = {
    isDirty: false,
    isValid: false,
    errorMsg: '',
  }

  componentDidMount() {
    if (this.props.value && this.props.value.length > 0) {
      this.setState({ isDirty: true }, this.validate(this.props.value))
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.forceDirty && !prevProps.forceDirty && !this.state.isDirty) {
      this.setState({ isDirty: true }, this.validate(this.props.value || ''))
    } else if (this.props.value !== prevProps.value) {
      this.validate(this.props.value || '')
    }
  }

  onChange = (e) => {
    const val = e.currentTarget.value
    this.props.handleInput(val)
    this.state.isDirty ? this.validate(val) : this.setState({ isDirty: true }, this.validate(val))
  }

  validate = (value) => {
    const I18n = this.context

    let isValid = true
    const msgs = []

    if (this.props.validations.indexOf('required') >= 0) {
      if (value === '') {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.required'))
      }
    }

    this.setState(
      {
        isValid,
        errorMsg: msgs.join(', '),
      },
      () => {
        ;(this.state.isDirty || isValid) && this.props.handleValid(isValid)
      }
    )
  }

  render() {
    const showError = !this.state.isValid && this.state.isDirty
    const validate = this.props.validations ? this.props.validations.indexOf('required') >= 0 : false

    return (
      <div className={this.props.wrapClass || ''}>
        {this.props.label && <label>{this.props.label}</label>}
        <div className={this.props.addon ? 'input-group' : ''}>
          {this.props.addon && (
            <span className='input-group-prepend'>
              <span className='input-group-text'>{this.props.addon}</span>
            </span>
          )}
          <select
            required={validate}
            className={`${this.props.class} ${showError && 'is-invalid'}`}
            onChange={this.onChange}
          >
            {this.props.options}
          </select>
          {showError && !this.props.hideErrorMsg && <div className='invalid-feedback'>{this.state.errorMsg}</div>}
        </div>
      </div>
    )
  }
}

Select.contextType = I18nContext

export default Select
