import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

import { EloTooltip } from '@elo-ui/components/elo-tooltip'
import { EloInfoIcon } from '@elo-ui/components/icons/regular'

import { TEST_IDS, TooltipProps } from '@elo-ui/types'

import '../elo-input.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  defaultValue?: string
  label?: string
  labelDescription?: string
  required?: boolean
  error?: boolean
  success?: boolean
  successText?: string
  errorText?: string
  hintText?: string
  dataTestId?: string
  prefix: string
  includePrefixToLink?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  tooltipOptions?: TooltipProps
}

export const EloInputLink: React.FC<Props> = ({
  id,
  name,
  placeholder = '',
  defaultValue = '',
  prefix = '',
  label = '',
  labelDescription = '',
  error = false,
  success = false,
  errorText = '',
  successText = '',
  hintText = '',
  dataTestId = TEST_IDS.input,
  disabled = false,
  className,
  onChange,
  onBlur,
  onFocus,
  required,
  includePrefixToLink = false,
  tooltipOptions,
}) => {
  const [currentValue, setCurrentValue] = useState('')

  useEffect(() => {
    if (defaultValue) {
      setCurrentValue(defaultValue)
    }
  }, [defaultValue])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(event.target.value)

    if (includePrefixToLink) {
      event.target.value = prefix + event.target.value
    }

    onChange(event)
  }, [])

  const inputClasses = classNames(className, 'elo-input', `elo-input--link`, {
    'elo-input--error': error,
    'elo-input--success': success,
    disabled,
  })

  return (
    <div className={inputClasses}>
      <label className='elo-input__label'>
        {label && (
          <span className='elo-input__label-text'>
            {label}
            {required && <span className='elo-input__label-required'>*</span>}
            {labelDescription && <span className='elo-input__label-description'> {labelDescription} </span>}
            {tooltipOptions && (
              <span className='elo-input__label-tooltip'>
                <EloTooltip {...tooltipOptions}>
                  {tooltipOptions.children ? tooltipOptions.children : <EloInfoIcon size={16} />}
                </EloTooltip>
              </span>
            )}
          </span>
        )}
        <div className='elo-input__text'>
          <span className='elo-input__prefix'>{prefix}</span>
          <input
            type='text'
            value={currentValue}
            aria-disabled={disabled}
            data-testid={dataTestId}
            tabIndex={disabled ? -1 : 0}
            readOnly={disabled}
            onChange={handleChange}
            {...{
              id,
              name,
              placeholder,
              onBlur,
              onFocus,
              required,
            }}
          />
        </div>
      </label>
      {success && successText && <span className='elo-input__success'>{successText}</span>}
      {error && errorText && <span className='elo-input__error'>{errorText}</span>}
      {hintText && <span className='elo-input__hint'>{hintText}</span>}
    </div>
  )
}
