import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IBAN from 'iban'

import { validators } from 'utils/validators.utils'
import compareTwoStrings from 'utils/stringComparison.utils'
import { I18nContext } from '@elo-kit/components/i18n/i18n'

const inputProps = {
  class: PropTypes.string,
  type: PropTypes.string,
}

// TODO: separate task
class Input extends Component {
  state = {
    isDirty: false,
    isValid: false,
    errorMsg: '',
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.Input.focus()
      const tempValue = this.Input.value
      this.Input.value = ''
      this.Input.value = tempValue
    }
    if (this.props.value && this.props.value.length > 0) {
      this.setState({ isDirty: true }, this.validate(this.props.value))
    } else {
      this.validate('')
    }
  }

  componentDidUpdate(prevProps) {
    const { forceDirty, value, locale, validations, vatValidByVies, isValidVat, isValidCountry, isInvalidZip } =
      this.props
    const validationsChanged = !(
      validations.length === prevProps.validations.length &&
      validations.sort().every((newValidation, index) => newValidation === prevProps.validations.sort()[index])
    )
    if (forceDirty && !prevProps.forceDirty && !this.state.isDirty) {
      this.setState({ isDirty: true }, this.validate(value || ''))
    } else if (
      value !== prevProps.value ||
      locale !== prevProps.locale ||
      validationsChanged ||
      vatValidByVies !== prevProps.vatValidByVies ||
      isValidVat !== prevProps.isValidVat ||
      isValidCountry !== prevProps.isValidCountry ||
      isInvalidZip !== prevProps.isInvalidZip
    ) {
      this.validate(value || '')
    }
  }

  onBlur = (e) => {
    const val = e.currentTarget.value
    const handler = () => {
      !!this.props.handleBlur ? this.props.handleBlur(this.props.name) : this.handleValue(val)
    }
    this.state.isDirty ? handler() : this.setState({ isDirty: true }, handler())
  }

  onChange = (e) => {
    this.handleValue(e.currentTarget.value)
  }

  handleValue = (val) => {
    this.props.handleInput(val)
    this.validate(val)
  }

  bicIsValid = (val) => /^([a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$/.test(val)

  validate = (value) => {
    const { emailData, validations, handleValid, vatComplianceField, vatValidByVies, handleInvalid, isVat } = this.props
    const I18n = this.context

    let isValid = true
    let msgs = []
    value = typeof value === 'number' ? value : value.trim()

    if (validations.indexOf('vatCompliance') >= 0) {
      if (compareTwoStrings(value.toLowerCase(), vatComplianceField.toLowerCase()) < 0.8) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.company_name_doesnt_match_with_id'))
        handleInvalid && handleInvalid()
      }
    }

    if (validations.indexOf('required') >= 0) {
      if (value === '') {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.required'))
      }
    }

    if (validations.indexOf('matchWithCountry') >= 0) {
      if (this.props.isInvalidZip) {
        isValid = false
        msgs.push(I18n.t('react.shared.checkout.zip_not_match_country'))
      }
    }

    if (validations.indexOf('minValue') >= 0) {
      if (parseFloat(value) < this.props.minValue) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.number_to_small'))
      }
    }

    if (validations.indexOf('maxValue') >= 0) {
      if (parseFloat(value) > this.props.maxValue) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.number_to_big'))
      }
    }

    if (validations.indexOf('include') >= 0) {
      if (value.indexOf(this.props.shouldInclude) < 0) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.should_include', { val: this.props.shouldInclude }))
      }
    }

    if (validations.indexOf('minlength') >= 0) {
      if (this.props.minlength > value.length) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.too_short', { min: this.props.minlength }))
      }
    }

    if (validations.indexOf('url') >= 0) {
      const pattern =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/
      isValid = pattern.test(value)
      msgs.push(I18n.t('react.shared.validations.url_invalid'))
    }

    if (validations.indexOf('maxlength') >= 0) {
      if (this.props.maxlength < value.length) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.too_long', { max: this.props.maxlength }))
      }
    }

    if (validations.indexOf('identical') >= 0) {
      if (this.props.identicalTo != value) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.not_identical'))
      }
    }

    if (validations.indexOf('email') >= 0) {
      if (!validators.isEmail(value)) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_email'))
      }
    }

    if (validations.includes('emailConfirmation')) {
      if (!validators.isEmail(value)) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_email'))
      } else if (emailData !== value) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.email_not_match'))
      }
    }

    if (validations.indexOf('iban') >= 0) {
      if (!IBAN.isValid(value)) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_iban'))
      }
    }

    if (validations.indexOf('bic') >= 0) {
      if (!this.bicIsValid(value)) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_bic'))
      }
    }

    if (validations.indexOf('cvv') >= 0) {
      if (!validators.isInt(value) || value.trim().length !== 3) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_cvv'))
      }
    }

    if (validations.indexOf('vat') >= 0 || validations.indexOf('viesVatValidation') >= 0) {
      const vatValid =
        validations.indexOf('vat') >= 0 &&
        this.props.isValidVat &&
        this.props.isValidCountry &&
        validations.indexOf('viesVatValidation') >= 0 &&
        vatValidByVies

      if (!vatValid) {
        let errorMessage = I18n.t('react.shared.validations.invalid_vat_country')
        if (!this.props.isValidVat) {
          errorMessage = I18n.t('react.shared.validations.invalid_vat')
        }
        if (this.props.vatData?.error) {
          errorMessage = I18n.t('react.shared.validations.service_unavailable')
        }

        isValid = false
        msgs.push(errorMessage)
      }
    }

    if (validations.indexOf('required') < 0 && value == '') {
      isValid = true
      msgs = []
    }
    this.setState(
      {
        isValid: isValid,
        errorMsg: msgs.join(', '),
      },
      () => {
        ;(this.state.isDirty || isValid || isVat) && handleValid(isValid)
      }
    )
  }

  isInputGroup = () => !!(this.props.addon || this.props.submitButton || this.props.cancelButton)

  render() {
    const showError = !this.state.isValid && this.state.isDirty
    const showSuccess =
      this.state.isValid &&
      this.state.isDirty &&
      this.props.value &&
      this.props.value.length > 0 &&
      this.props.successMsg
    const validate = this.props.validations ? this.props.validations.indexOf('required') >= 0 : false
    const TagName = this.props.tagName || 'input'
    const testId = this.props.testId || 'payer-form-input'
    return (
      <div className={this.props.wrapClass || ''} data-testid={testId}>
        {this.props.label && <label className={validate ? 'required-input-label' : ''}>{this.props.label}</label>}
        <div className={this.isInputGroup() ? 'input-group' : ''}>
          {this.props.addon && (
            <span className='input-group-prepend'>
              <span className='input-group-text'>{this.props.addon}</span>
            </span>
          )}
          <TagName
            required={validate}
            autoComplete={this.props.autoComplete || 'off'}
            className={`${this.props.class} ${showError && 'is-invalid'} ${showSuccess && 'is-valid'}`}
            placeholder={this.props.placeholder}
            value={this.props.value}
            type={this.props.type || 'text'}
            minLength={this.props.minlength}
            maxLength={this.props.maxlength}
            onChange={this.onChange}
            onBlur={this.onBlur}
            disabled={this.props.disabled}
            onCopy={(e) => {
              this.props.disableCopyPaste && e.preventDefault()
              return false
            }}
            onPaste={(e) => {
              this.props.disableCopyPaste && e.preventDefault()
              return false
            }}
            ref={(input) => {
              this.Input = input
            }}
          />
          {(this.props.submitButton || this.props.cancelButton) && (
            <span className='input-group-append'>
              {!!this.props.submitButton &&
                (this.state.isValid || this.props.showSubmitWhenInvalid) &&
                this.props.submitButton}
              {!!this.props.cancelButton && this.props.cancelButton}
            </span>
          )}
          {showError && !this.props.hideErrorMsg && (
            <div
              className='invalid-feedback'
              dangerouslySetInnerHTML={{ __html: this.state.errorMsg }}
              data-testid={`${testId}-error-message`}
            />
          )}
          {showSuccess && <div className='valid-feedback'>{this.props.successMsg}</div>}
        </div>
      </div>
    )
  }
}

Input.propTypes = inputProps
Input.contextType = I18nContext

export default Input
