import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useClickOutside } from '@elo-ui/hooks/use-click-outside'
import classNames from 'classnames'
import { EloPopoverTitle, EloPopoverContent } from './components'

import './elo-popover.scss'

interface RectPart {
  top: number
  left: number
  height: number
  width: number
}

export const POPOVER_PLACEMENTS = [
  'top-left',
  'top-center',
  'top-right',
  'bottom-left',
  'bottom-center',
  'bottom-right',
  'right-top',
  'right-center',
  'right-bottom',
  'left-top',
  'left-center',
  'left-bottom',
] as const

const TRIANGLE_GAP = 15
const TRIANGLE_HEIGHT = 7
const TRIANGLE_CENTER = TRIANGLE_GAP + 7
const GAP = 8
export interface PopoverProps {
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>
  onClickOutside?: () => void
  placement: (typeof POPOVER_PLACEMENTS)[number]
  title?: string
  children?: React.ReactNode
  showFooter?: boolean
  isSmall?: boolean
  show?: boolean
  content?: React.ReactNode
  footer?: React.ReactNode
  usePortal?: boolean
  className?: string
}

export const EloPopover: React.FC<PopoverProps> = ({
  onMouseEnter,
  onMouseLeave,
  onClickOutside,
  placement,
  children,
  showFooter,
  title,
  content,
  footer,
  isSmall,
  show,
  usePortal = false,
  className = '',
}) => {
  const [isVisible, setIsVisible] = useState(show)
  const toggleIsVisible = useCallback(() => {
    setIsVisible((isVisible) => !isVisible)
  }, [setIsVisible])
  const popoverClass = classNames('elo-popover', `elo-popover--${placement}`, { 'elo-popover--sm': isSmall }, className)
  const wrapperClassNames = classNames('elo-popover__wrapper', { 'elo-popover__wrapper--no-portal': !usePortal })
  const popoverRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [styles, setStyles] = useState()

  useEffect(() => {
    setIsVisible(show)
  }, [show, setIsVisible])

  if (onClickOutside) {
    useClickOutside(contentRef, onClickOutside)
  }
  if (usePortal) {
    useEffect(() => {
      const updatePosition = () => {
        if (popoverRef.current && contentRef.current && isVisible) {
          setStyles(getStyles(popoverRef.current.getBoundingClientRect(), contentRef.current.getBoundingClientRect()))
        }
      }

      window.addEventListener('resize', updatePosition)
      window.addEventListener('scroll', updatePosition)
      updatePosition()

      return () => {
        window.removeEventListener('resize', updatePosition)
        window.removeEventListener('scroll', updatePosition)
      }
    }, [placement, isVisible, show])
  }

  const handleNegative = (value: number) => {
    const contentRect = contentRef.current.getBoundingClientRect()

    if (value + contentRect.width > window.innerWidth) {
      return value - (value + contentRect.width - window.innerWidth)
    }

    return value < 0 ? 0 : value
  }

  const getStyles = (wrapperRect: RectPart, contentRect: RectPart) => {
    let styles = null

    switch (placement) {
      case 'top-left':
        styles = {
          top: `${wrapperRect.top + wrapperRect.height + GAP + window.scrollY}px`,
          left: `${handleNegative(wrapperRect.left + wrapperRect.width / 2 - TRIANGLE_CENTER + window.scrollX)}px`,
        }
        break
      case 'top-right':
        styles = {
          top: `${wrapperRect.top + wrapperRect.height + GAP + window.scrollY}px`,
          left: `${handleNegative(
            wrapperRect.left - contentRect.width + wrapperRect.width + window.scrollX + TRIANGLE_GAP
          )}px`,
        }
        break
      case 'top-center':
        styles = {
          top: `${wrapperRect.top + wrapperRect.height + GAP + window.scrollY}px`,
          left: `${handleNegative(
            wrapperRect.left - contentRect.width / 2 + wrapperRect.width / 2 + window.scrollX
          )}px`,
        }
        break
      case 'bottom-left':
        styles = {
          top: `${wrapperRect.top - wrapperRect.height / 2 - contentRect.height + window.scrollY}px`,
          left: `${handleNegative(wrapperRect.left + wrapperRect.width / 2 - TRIANGLE_CENTER + window.scrollX)}px`,
        }
        break
      case 'bottom-right':
        styles = {
          top: `${wrapperRect.top - wrapperRect.height / 2 - contentRect.height + window.scrollY}px`,
          left: `${handleNegative(
            wrapperRect.left - contentRect.width + wrapperRect.width + TRIANGLE_GAP + window.scrollX
          )}px`,
        }
        break
      case 'bottom-center':
        styles = {
          top: `${wrapperRect.top - wrapperRect.height / 2 - contentRect.height + window.scrollY}px`,
          left: `${handleNegative(
            wrapperRect.left - contentRect.width / 2 + wrapperRect.width / 2 + window.scrollX
          )}px`,
        }
        break
      case 'left-top':
        styles = {
          top: `${wrapperRect.top - wrapperRect.height / 2 + window.scrollY}px`,
          left: `${handleNegative(wrapperRect.left + wrapperRect.width + TRIANGLE_HEIGHT + window.scrollX)}px`,
        }
        break
      case 'left-bottom':
        styles = {
          top: `${wrapperRect.top - contentRect.height + wrapperRect.height + TRIANGLE_GAP + window.scrollY}px`,
          left: `${handleNegative(wrapperRect.left + wrapperRect.width + GAP + window.scrollX)}px`,
        }
        break
      case 'left-center':
        styles = {
          top: `${wrapperRect.top - contentRect.height / 2 + GAP + window.scrollY}px`,
          left: `${handleNegative(wrapperRect.left + wrapperRect.width + GAP + window.scrollX)}px`,
        }
        break
      case 'right-top':
        styles = {
          top: `${wrapperRect.top - wrapperRect.height / 2 + window.scrollY}px`,
          left: `${handleNegative(wrapperRect.left - wrapperRect.width / 2 - contentRect.width + window.scrollX)}px`,
        }
        break
      case 'right-bottom':
        styles = {
          top: `${wrapperRect.top - contentRect.height + wrapperRect.height + TRIANGLE_GAP + window.scrollY}px`,
          left: `${handleNegative(wrapperRect.left - wrapperRect.width / 2 - contentRect.width + window.scrollX)}px`,
        }
        break
      case 'right-center':
        styles = {
          top: `${wrapperRect.top - contentRect.height / 2 + GAP + window.scrollY}px`,
          left: `${handleNegative(wrapperRect.left - wrapperRect.width / 2 - contentRect.width + window.scrollX)}px`,
        }
        break
    }

    return styles
  }

  const getPopoverBody = () => (
    <div className={popoverClass} ref={contentRef} style={styles}>
      {title && <EloPopoverTitle title={title} />}
      {content && <EloPopoverContent content={content} contentClass='elo-popover__content' />}
      {showFooter && footer && <EloPopoverContent content={footer} contentClass='elo-popover__footer' />}
      <span className='elo-popover__arrow' />
    </div>
  )

  return (
    <div
      ref={popoverRef}
      className={wrapperClassNames}
      onMouseEnter={onMouseEnter || toggleIsVisible}
      onMouseLeave={onMouseLeave || toggleIsVisible}
    >
      {children}
      {isVisible && (usePortal ? createPortal(getPopoverBody(), document.body) : getPopoverBody())}
    </div>
  )
}
