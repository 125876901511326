import React, { useEffect } from 'react'

const useClickOutside = (ref: React.RefObject<HTMLElement>, handler: (any) => void) => {
  useEffect(() => {
    let startedInside = false
    let startedWhenMounted = false

    const listener = (event) => {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside || !startedWhenMounted) return
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return

      handler(event)
    }

    const validateEventStart = (event) => {
      startedWhenMounted = Boolean(ref.current)
      startedInside = Boolean(ref.current) && Boolean(ref.current?.contains(event.target))
    }

    document.addEventListener('mousedown', validateEventStart)
    document.addEventListener('touchstart', validateEventStart)
    document.body.addEventListener('click', listener)

    return () => {
      document.removeEventListener('mousedown', validateEventStart)
      document.removeEventListener('touchstart', validateEventStart)
      document.body.removeEventListener('click', listener)
    }
  }, [])
}

export { useClickOutside }
