import React, { useEffect, useRef, KeyboardEvent } from 'react'
import classNames from 'classnames'
import CreatableSelect from 'react-select/creatable'
import { components } from 'react-select'

import { EloTag } from '@elo-ui/components/elo-tag'
import { TEST_IDS, TooltipProps } from '@elo-ui/types'

import { EloInfoIcon } from '@elo-ui/components/icons/regular'
import { EloTooltip } from '@elo-ui/components/elo-tooltip'

import '../elo-input.scss'

// TODO: Use react-select option props and extend it
interface Option {
  label: string
  value: React.ReactNode
}

interface Components {
  DropdownIndicator?: () => React.ReactNode
  IndicatorSeparator?: () => React.ReactNode
  Option?: (props: any) => React.ReactNode // TODO: Use react-select option props
}

interface ActionMeta {
  action: 'set-value' | 'input-change' | 'input-blur' | 'menu-close' | 'create-option'
  prevValue: string
}

// TODO: Use react-select props and extend it
interface Props {
  label?: string
  labelDescription?: string
  required?: boolean
  error?: boolean
  success?: boolean
  disabled?: boolean
  successText?: string
  errorText?: string | React.ReactNode
  hintText?: string
  dataTestId?: string
  className?: string
  options: Option[]
  placeholder?: string
  onChange?: (value: Option[], actionMeta?: ActionMeta) => Option[] | void
  isOptionDisabled?: (option: Option) => boolean
  value?: Option[]
  components?: Components
  closeMenuOnSelect?: boolean
  isClearable?: boolean
  formatOptionLabel?: (data: Option) => React.ReactNode
  formatCreateLabel?: (inputValue: string) => React.ReactNode
  isValidNewOption?: (inputValue?: string, value?: ReadonlyArray<Option>, options?: ReadonlyArray<Option>) => boolean
  inputValue?: string
  onInputChange?: (newInputValue?: string, actionMeta?: ActionMeta) => string | void
  onKeyDown?: (e: KeyboardEvent<HTMLElement>) => void
  tooltipOptions?: TooltipProps
  children?: React.ReactNode
}

const MultiValue = ({ removeProps: { onClick: onDelete }, children }) => <EloTag onDelete={onDelete}>{children}</EloTag>

const MenuList = ({ children, focusedOption, ...props }) => {
  const { __isNew__ } = focusedOption || {}

  if (!children?.length || __isNew__) {
    return null
  }

  return <components.MenuList {...props}>{children}</components.MenuList>
}

export const DefaultOption = (props) => {
  const {
    data: { __isNew__ },
  } = props

  if (__isNew__) {
    return null
  }

  return <components.Option {...props}>{props.children}</components.Option>
}

export const EloInputWithTags: React.FC<Props> = (props) => {
  const {
    options = [],
    label,
    labelDescription,
    required,
    error,
    success,
    successText,
    errorText,
    hintText,
    className,
    disabled,
    placeholder,
    dataTestId = TEST_IDS.input,
    onChange,
    closeMenuOnSelect = true,
    isClearable = false,
    formatOptionLabel,
    formatCreateLabel,
    isValidNewOption,
    components: { DropdownIndicator, IndicatorSeparator, Option } = {},
    value,
    isOptionDisabled,
    inputValue,
    onInputChange,
    onKeyDown,
    tooltipOptions,
  } = props

  const inputClasses = classNames(className, 'elo-input elo-input-with-tags', {
    'elo-input--error': error,
    'elo-input--success': success,
    disabled,
    'elo-input-with-tags--with-actions': isClearable || DropdownIndicator,
  })

  const inputEl = useRef(null)

  useEffect(() => {
    if (inputEl.current) {
      inputEl?.current?.addEventListener('contextmenu', (e) => {
        e.preventDefault()
      })
    }
  }, [])

  return (
    <div ref={inputEl} className={inputClasses} data-testid={dataTestId}>
      <label className='elo-input__label'>
        {label && (
          <span className='elo-input__label-text'>
            {label}
            {required && <span className='elo-input__label-required'>*</span>}
            {labelDescription && <span className='elo-input__label-description'> {labelDescription} </span>}
            {tooltipOptions && (
              <span className='elo-input__label-tooltip'>
                <EloTooltip {...tooltipOptions}>
                  {tooltipOptions.children ? tooltipOptions.children : <EloInfoIcon />}
                </EloTooltip>
              </span>
            )}
          </span>
        )}
        <CreatableSelect
          isMulti
          options={options}
          placeholder={placeholder}
          inputValue={inputValue}
          onInputChange={onInputChange}
          components={{
            DropdownIndicator: DropdownIndicator,
            IndicatorSeparator: IndicatorSeparator,
            MultiValue,
            NoOptionsMessage: () => null,
            Option: Option || DefaultOption,
            MenuList,
          }}
          onChange={onChange}
          classNamePrefix='elo-input-with-tags'
          isClearable={isClearable}
          closeMenuOnSelect={closeMenuOnSelect}
          formatOptionLabel={formatOptionLabel}
          formatCreateLabel={formatCreateLabel}
          isValidNewOption={isValidNewOption}
          isDisabled={disabled}
          value={value}
          isOptionDisabled={isOptionDisabled}
          onKeyDown={onKeyDown}
        />
      </label>
      {success && successText && <span className='elo-input__success'>{successText}</span>}
      {error && errorText && <span className='elo-input__error'>{errorText}</span>}
      {hintText && <span className='elo-input__hint'>{hintText}</span>}
    </div>
  )
}
