import React, { ChangeEvent, FocusEvent, useCallback, useState } from 'react'
import PhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2'
import classNames from 'classnames'

import { EloInfoIcon } from '@elo-ui/components/icons/regular'
import { EloTooltip } from '@elo-ui/components/elo-tooltip'

import { TEST_IDS, TooltipProps } from '@elo-ui/types'

import 'react-phone-input-2/lib/style.css'
import '../elo-input.scss'
import './styles.scss'

interface Props extends PhoneInputProps {
  id?: string
  name?: string
  defaultValue?: string
  className?: string
  label?: string
  labelDescription?: string
  required?: boolean
  error?: boolean
  success?: boolean
  successText?: string
  errorText?: string
  hintText?: string
  dataTestId?: string
  inputSize?: 'medium' | 'large'
  tooltipOptions?: TooltipProps
  renderIcon?: () => React.ReactNode
  onChange?: (
    value: string,
    data: Record<any, never> | CountryData,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>, data: Record<any, never> | CountryData) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>, data: CountryData | Record<any, never>) => void
  fullWidth?: boolean
}

export const EloPhoneInput: React.FC<Props> = (props: Props) => {
  const {
    value,
    country = 'de',
    name,
    id,
    defaultValue = '',
    label = '',
    labelDescription = '',
    error = false,
    success = false,
    errorText = '',
    successText = '',
    hintText = '',
    disabled = false,
    className,
    onChange,
    dataTestId = TEST_IDS.input,
    required,
    inputSize = 'large',
    tooltipOptions,
    renderIcon,
    fullWidth,
  } = props
  const [currentValue, setCurrentValue] = useState(defaultValue)

  const inputClasses = classNames(className, 'elo-input', `elo-input--phone`, `elo-input--${inputSize}`, {
    'elo-input--error': error,
    'elo-input--success': success,
    disabled,
  })

  const buttonClass = classNames('elo-input--phone__button', { 'elo-input--phone__disabled': disabled })

  const handleChange = useCallback(
    (
      value: string,
      countryData: CountryData | Record<string, never>,
      event: ChangeEvent<HTMLInputElement>,
      formattedValue: string
    ) => {
      setCurrentValue(event.target.value)
      onChange(value, countryData, event, formattedValue)
    },
    []
  )

  const phoneInputClasses = classNames('elo-input--phone__input', {
    'elo-input--phone__input--full-width': fullWidth,
  })

  return (
    <div className={inputClasses}>
      <label className='elo-input__label'>
        {label && (
          <span className='elo-input__label-text'>
            {label}
            {required && <span className='elo-input__label-required'>*</span>}
            {labelDescription && <span className='elo-input__label-description'> {labelDescription} </span>}
            {tooltipOptions && (
              <span className='elo-input__label-tooltip'>
                <EloTooltip {...tooltipOptions}>
                  {tooltipOptions.children ? tooltipOptions.children : <EloInfoIcon />}
                </EloTooltip>
              </span>
            )}
          </span>
        )}

        <PhoneInput
          value={typeof value !== 'undefined' ? value : currentValue}
          onChange={handleChange}
          country={country}
          name={name}
          id={id}
          dataTestId={dataTestId}
          inputClass={phoneInputClasses}
          dropdownClass='elo-input--phone__drop-down'
          buttonClass={buttonClass}
          specialLabel=''
          excludeCountries={['ru']}
          {...props}
        />
        {renderIcon && renderIcon()}
      </label>
      {success && successText && <span className='elo-input__success'>{successText}</span>}
      {error && errorText && <span className='elo-input__error'>{errorText}</span>}
      {hintText && <span className='elo-input__hint'>{hintText}</span>}
    </div>
  )
}
