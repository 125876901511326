import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

import { EloTooltip } from '@elo-ui/components/elo-tooltip/elo-tooltip'
import { EloInfoIcon } from '@elo-ui/components/icons/regular'

import { TEST_IDS, TooltipProps } from '@elo-ui/types'

import '../elo-input.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  defaultValue?: number
  label?: string
  labelDescription?: string
  required?: boolean
  error?: boolean
  success?: boolean
  successText?: string
  errorText?: string
  hintText?: string
  dataTestId?: string
  onChange?: (value: ChangeEvent<HTMLInputElement> | number) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  min?: number
  max?: number
  inputSymbolPosition?: 'left' | 'right'
  inputSymbol?: string
  tooltipOptions?: TooltipProps
  allowEmptyValue?: boolean
  fractional?: number
}

enum SymbolPosition {
  left = 'left',
  right = 'right',
}

const getFieldValue = ({ maxFractional, maxValue, minValue, value = '', allowEmptyValue }) => {
  const [integer, fractional] = String(value).split('.')
  let newValue = fractional ? Number(`${integer}.${fractional.slice(0, maxFractional)}`) : Number(value)

  if (value === '') {
    newValue = allowEmptyValue ? '' : (0 as any)
  } else if (!!maxValue && newValue > maxValue) {
    newValue = maxValue
  } else if (!!minValue && newValue < minValue) {
    newValue = minValue
  }

  return newValue
}

export const EloInputWithSign: React.FC<Props> = ({
  id,
  name,
  placeholder = '',
  defaultValue = 0,
  label = '',
  labelDescription = '',
  error = false,
  success = false,
  errorText = '',
  successText = '',
  hintText = '',
  dataTestId = TEST_IDS.input,
  disabled = false,
  className,
  onChange,
  onBlur,
  onFocus,
  required,
  min,
  max,
  inputSymbolPosition = SymbolPosition.left,
  inputSymbol = '$',
  tooltipOptions,
  allowEmptyValue = false,
  fractional,
}) => {
  const [currentValue, setCurrentValue] = useState<number | string>(defaultValue)

  useEffect(() => {
    setCurrentValue(defaultValue)
    if (defaultValue <= min) {
      setCurrentValue(min)
      onChange(min)
    }

    if (defaultValue >= max) {
      setCurrentValue(max)
      onChange(max)
    }
  }, [defaultValue])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newValue = getFieldValue({
      maxFractional: fractional,
      maxValue: max,
      minValue: min,
      value: event.target.value,
      allowEmptyValue,
    })

    setCurrentValue(newValue)
    onChange(newValue)
  }, [])

  const inputClasses = classNames(className, 'elo-input', `elo-input--with-sign`, {
    'elo-input--error': error,
    'elo-input--success': success,
    disabled,
  })

  const spanClasses = classNames(className, 'elo-input', `elo-input--with-sign__${inputSymbolPosition}`)
  const inputStyle = inputSymbol.length > 2 ? { [`padding-${inputSymbolPosition}`]: '46px' } : {}

  return (
    <div className={inputClasses}>
      <label className='elo-input__label'>
        {label && (
          <span className='elo-input__label-text'>
            {label}
            {required && <span className='elo-input__label-required'>*</span>}
            {labelDescription && <span className='elo-input__label-description'> {labelDescription} </span>}
            {tooltipOptions && (
              <span className='elo-input__label-tooltip'>
                <EloTooltip {...tooltipOptions}>{tooltipOptions.children || <EloInfoIcon size={16} />}</EloTooltip>
              </span>
            )}
          </span>
        )}
        <div className='elo-input__number'>
          <span className={spanClasses}>{inputSymbol}</span>
          <input
            style={inputStyle}
            className={inputSymbolPosition === 'left' ? 'left' : 'right'}
            type='number'
            value={currentValue}
            aria-disabled={disabled}
            data-testid={dataTestId}
            tabIndex={disabled ? -1 : 0}
            readOnly={disabled}
            max={max}
            min={min}
            onKeyDown={(evt) => ['e', 'E', '-', '+'].includes(evt.key) && evt.preventDefault()}
            onChange={handleChange}
            {...{
              id,
              name,
              placeholder,
              onFocus,
              onBlur,
              required,
            }}
          />
        </div>
      </label>
      {success && successText && <span className='elo-input__success'>{successText}</span>}
      {error && errorText && <span className='elo-input__error'>{errorText}</span>}
      {hintText && <span className='elo-input__hint'>{hintText}</span>}
    </div>
  )
}
