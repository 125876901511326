import React from 'react'

interface ContentProps {
  content?: React.ReactNode
  contentClass: string
}

export const EloPopoverContent: React.FC<ContentProps> = ({ content, contentClass }) => {
  const Component = typeof content === 'function' ? content : null

  return <div className={contentClass}>{Component ? <Component /> : content}</div>
}
